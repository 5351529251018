.card{
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    font-family: "PT Sans" !important;
}

.card-header {
    background-color: #f4f4f4;
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    border-radius: 3px 3px 0 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;

    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.card-body {
    padding: 19px 30px 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
    border-radius: 0 0 3px 3px;
}


.card-header>div {
    height: 49.6px;
    -ms-flex-align: center;
    align-items: center;
}

.d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

input, textarea{
    font-family: "PT Sans" !important;
}

.card-header>div {
    height: 49.6px;
    -ms-flex-align: center;
    align-items: center;
}
